<template>
  <Category/>
</template>

<script>
import Category from './components/Category.vue';

export default {
  name: 'App',
  components: {
    Category
  }
}
</script>

<style>
  @import "./assets/reset.css";
</style>
