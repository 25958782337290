import request from '../util/request.js' //引入我们封装好的request


//定义一个函数 getLogin
export function getModels(params) {
    return request({  //利用我们封装好的request发送请求
        url: '/api/models',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'get',//请求方法
        params: params//向后端传递数据
    })
}
