<template>
  <div class="live-classify">
    <na-header/>
    <div class="live-list">
      <div class="tab">
        <h2 class="title">LIVE PORN CAMS WITH MATCHES TO YOUR LIVE SEX SEARCH SELECTIONS</h2>
        <p>  
          We find the best live porn cams across many different adult cam sites like Live Jasmin, Chaturbate, Cam4 and Stripchat. With CamFoxxy you are never more than a click away from the hottest online nude live girls, guys and even hundreds of tranny cams. We regularly stock over 20,000 free camfoxxy at any given time. Weekends tend to push the number of nude porn cams up to over 30,000 horny chat models ready to make your night one to remember. The possibilities for camfoxxy are endless when you use our search engine to find free sex cam partners from all over the world. Our live nude cams work across a myriad of electronic devices including mobile sex cams, iPad porn cams, tablets, computers, laptops and more. Say goodbye to tired porn videos and hello to 4K UHD nude live webcams!
        </p>
      </div>
      <div class="list-content">
        <div class="item-wrap">
          <LiveRoom class="cls-item" v-for="item in list" :key="item.id" :item="item" />
        </div>
      </div>
      <!-- <div class="pagination">
        <el-pagination
          :page-size="25"
          :total="totalCount"
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page="page"
          @current-change="changePage"
          background />
      </div> -->
    </div>
  </div>
</template>

<script>
import LiveRoom from './LiveRoom.vue';
import { getModels } from '@/api/model';

export default {
  layout: 'common-footer',
  name: 'live-classify',
  created () {
        this.onLoad();
  },
  components: {
    LiveRoom
  },
  methods: {
    onLoad() {
      getModels({limit: 20}).then(res => {
        console.log(res)
        const list = []
        for (let i = 0; i < res.data.count; i++) {
          list.push({
            id: res.data.models[i].id,
            title: res.data.models[i].username,
            cover: res.data.models[i].snapshotUrl,
            nick_name: res.data.models[i].username,
            hot: res.data.models[i].viewersCount
          })
        }
        this.list = list
      })
    }
  },
  data () {
    return {
      type: 0,
      list: [],
      totalCount: 1000,
      page: 1
    }
  },
}
</script>

<style scoped lang="stylus">
  @import "../assets/variable.styl"
.live-classify
  .live-list
    width 100%
    margin 0 auto
    overflow hidden
    background-color #F5F5F5
    .tab
      width 1406px
      margin 0 auto
      margin-top 33px
      margin-bottom 10px
      padding: 20px
      align-items center
      background-color #fff
      &>p
        line-height: 150%
        padding-top: 10px
        color: #4a4a4a
        font-size: 14px
      &>a
        margin-right 48px
        font-size 16px
        color #666666
        &.active
          color $active-color
          font-weight bold
    .list-content
      padding-top: 20px
      width 1406px
      min-height 516px
      margin 0 auto
      &>h3
        margin-bottom 27px
        font-size 22px
        color #333333
      .item-wrap
        display flex
        flex-wrap wrap
        .cls-item
          margin-right 15px
          margin-bottom 36px
          &:nth-child(5n + 0)
            margin-right 0
  @media only screen and (max-width: 1406px)
    .live-list
      .tab
        width 1137px
      .list-content
        width 1137px
        .item-wrap
          .cls-item
            &:nth-child(5n + 0)
              margin-right 15px
            &:nth-child(4n + 0)
              margin-right 0
  .pagination
    width 1406px
    height 80px
    margin 0 auto
    margin-top 20px
    display flex
    justify-content center
    ::v-deep
      .el-pagination
        .el-pager li
          width 40px
          height 40px
          line-height 40px
          border-radius 5px
          border 1px solid #CCCCCC
          color #999999
          &.more
            border none
          &.active
            border none
            color #fff
        span:not([class*=suffix])
          height 40px
          line-height 40px
</style>